@use 'src/scss/underscore' as _;

.textField {
  @include _.form-text-field;
}

.description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: _.unit(0.5);
}
