const input = {
    username: 'Nazwa użytkownika',
    password: 'Hasło',
    email: 'E-mail',
    or_join: 'lub',
    phone_number: 'Numer telefonu',
    confirm_password: 'Potwierdź hasło',
    search_region_code: 'Kod regionu wyszukiwania',
};
export default Object.freeze(input);
