const get_started = {
    page_title: 'Loslegen',
    progress: 'Erste Schritte: {{completed}}/{{total}}',
    progress_dropdown_title: 'Was du machen kannst...',
    title: 'Etwas zum Erkunden, um dir zu helfen, erfolgreich zu sein',
    subtitle_part1: 'Ein paar Dinge, die du tun kannst, um schnell von Logto zu profitieren',
    subtitle_part2: 'Ich bin fertig mit der Einrichtung.',
    hide_this: 'Ausblenden',
    confirm_message: 'Bist du sicher, dass du diese Seite ausblenden willst? Diese Aktion kann nicht rückgängig gemacht werden.',
    check_preview_title: 'Live-Vorschau prüfen',
    check_preview_subtitle: 'Probiere die Logto-Anmeldung jetzt aus, um zu sehen, wie sie funktioniert',
    integration_title: 'Erstelle und integriere deine Anwendung',
    integration_subtitle: 'Richte die Logto-Authentifizierung für deine native, Single-Page-, Maschine-zu-Maschine- oder traditionelle Anwendung ein',
    custom_sie_title: 'Anmeldeerfahrung anpassen',
    custom_sie_subtitle: 'Schalte mit erweiterten Einstellungen ein breites Spektrum von Szenarios frei',
    passwordless_title: 'Skaliere die passwortlose Anmeldung, indem du eigene Connectoren hinzufügst',
    passwordless_subtitle: 'Probiere die passwortlose Anmeldung aus und ermögliche eine sichere und reibungslose Erfahrung für deine Kunden',
    community_title: 'Tritt unserer Discord-Community bei',
    community_subtitle: 'Trete unserem öffentlichen Kanal bei, um mit anderen Entwicklern zu chatten',
    management_api_title: 'Interagiere mit der Management-API',
    management_api_subtitle: 'Verbinde dein Authentifizierungssystem direkt mit unserer Management-API',
    further_readings_title: 'Weitere Lektüre',
    further_readings_subtitle: 'Schau dir unsere schrittweisen, szenariobasierten Dokumente ohne langweilige Konzepte an',
    add_rbac_title: 'Füge rollenbasierte Zugriffssteuerung hinzu, um deine Ressourcen zu schützen',
    add_rbac_subtitle: 'Steuere deine Ressourcen durch skalierbare Rollenberechtigung für verschiedene Anwendungsfälle.',
};
export default Object.freeze(get_started);
