const get_started = {
    page_title: 'Inizia',
    progress: 'Guida per iniziare: {{completed}}/{{total}}',
    progress_dropdown_title: 'Alcune cose che puoi fare...',
    title: 'Esplora per avere successo',
    subtitle_part1: 'Alcune cose che puoi fare per ottenere rapidamente il valore di Logto',
    subtitle_part2: 'Sono un professionista e ho completato tutti i passaggi.',
    hide_this: 'Nascondi questo',
    confirm_message: 'Sei sicuro di voler nascondere questa pagina? Questa azione non può essere annullata.',
    check_preview_title: "Controlla l'anteprima dal vivo",
    check_preview_subtitle: 'Prova subito Logto sign-in per vedere come funziona',
    integration_title: 'Crea ed integra la tua applicazione',
    integration_subtitle: "Configura l'autenticazione di Logto per la tua applicazione nativa, single page, machine to machine o tradizionale",
    custom_sie_title: "Personalizza l'esperienza di accesso",
    custom_sie_subtitle: 'Sblocca una vasta gamma di scenari con le impostazioni avanzate',
    passwordless_title: "Scala l'accesso senza password aggiungendo i tuoi connettori",
    passwordless_subtitle: "Prova l'accesso senza password e abilita un'esperienza sicura e priva di attriti per i tuoi clienti",
    community_title: 'Unisciti alla nostra community su Discord',
    community_subtitle: 'Entra nel nostro canale pubblico per parlare con altri sviluppatori',
    management_api_title: 'Interagisci con la Management API',
    management_api_subtitle: 'Collega direttamente il tuo sistema di autenticazione alla nostra Management API',
    further_readings_title: 'Ulteriori letture',
    further_readings_subtitle: 'Consulta la nostra documentazione passo-passo basata su scenari senza concetti noiosi',
    add_rbac_title: 'Aggiungi il controllo degli accessi basati sui ruoli per proteggere le tue risorse',
    add_rbac_subtitle: "Controlla le tue risorse attraverso l'autorizzazione basata su ruoli scalabile per utilizzi diversi.",
};
export default Object.freeze(get_started);
