const error = {
    general_required: `{{types, list(type: disjunction;)}} is vereist`,
    general_invalid: `De {{types, list(type: disjunction;)}} is ongeldig`,
    username_required: 'Gebruikersnaam is vereist',
    password_required: 'Wachtwoord is vereist',
    username_exists: 'Gebruikersnaam bestaat al',
    username_should_not_start_with_number: 'Gebruikersnaam mag niet beginnen met een getal',
    username_invalid_charset: 'Gebruikersnaam mag alleen letters, cijfers of underscores bevatten.',
    invalid_email: 'Het e-mailadres is ongeldig',
    invalid_phone: 'Het telefoonnummer is ongeldig',
    password_min_length: 'Wachtwoord vereist een minimum van {{min}} tekens',
    invalid_password: 'Wachtwoord vereist een minimum van {{min}} tekens en bevat een mix van letters, cijfers en symbolen.',
    passwords_do_not_match: 'Uw wachtwoorden komen niet overeen. Probeer het opnieuw.',
    invalid_passcode: 'De verificatiecode is ongeldig',
    invalid_connector_auth: 'De autorisatie is ongeldig',
    invalid_connector_request: 'De connectorgegevens zijn ongeldig',
    unknown: 'Onbekende fout. Probeer het later opnieuw.',
    invalid_session: 'Sessie niet gevonden. Ga terug en log opnieuw in.',
    timeout: 'Verzoek time-out. Probeer het later opnieuw.',
};
export default Object.freeze(error);
