const input = {
    username: 'Benutzername',
    password: 'Passwort',
    email: 'Email',
    or_join: 'oder',
    phone_number: 'Telefonnummer',
    confirm_password: 'Passwort bestätigen',
    search_region_code: 'Suche region code',
};
export default Object.freeze(input);
