@use 'src/scss/underscore' as _;

.item {
  display: flex;
  align-items: center;
  padding: _.unit(2.5) _.unit(4);
  user-select: none;

  .meta {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;

    .title {
      flex: 1 1 0;
      font: var(--font-body-2);
      @include _.text-ellipsis;
      margin-left: _.unit(2);
      max-width: fit-content;
    }

    .suspended {
      margin: 0 _.unit(1);
    }
  }

  .icon {
    color: var(--color-text-secondary);
  }

  &:hover {
    background: var(--color-hover);
  }
}
