@use 'src/scss/underscore' as _;

.tableError {
  display: flex;
  flex-direction: column;
  align-items: center;

  > *:not(:first-child) {
    margin-top: _.unit(2);
  }

  .title {
    text-align: center;
    font: var(--font-label-2);
  }

  .content {
    font: var(--font-body-2);
    color: var(--color-neutral-50);
  }
}
