@use 'src/scss/underscore' as _;

.content {
  font: var(--font-body-2);

  .info {
    margin-top: _.unit(6);
    background: var(--color-neutral-variant-90);
    padding: _.unit(5);
    border-radius: _.unit(2);

    .infoLine {
      display: flex;
      align-items: center;
      font: var(--font-body-2);

      &:not(:last-child) {
        margin-bottom: _.unit(2);
      }

      .infoContent {
        font: var(--font-label-2);
        padding-left: _.unit(1);
      }

      .operation {
        padding-left: _.unit(1);
      }

      .eyeIcon {
        color: var(--color-text-secondary);
      }
    }
  }
}
