@use 'src/scss/underscore' as _;

.item {
  display: flex;
  align-items: center;
  font: var(--font-body-2);
  padding: _.unit(2.5) _.unit(4);
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: var(--color-hover);
  }

  .name {
    @include _.text-ellipsis;
  }

  .count {
    flex-shrink: 0;
    margin-left: _.unit(2);
    color: var(--color-text-secondary);
  }
}
