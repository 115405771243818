const input = {
    username: 'ユーザー名',
    password: 'パスワード',
    email: 'メールアドレス',
    or_join: 'または',
    phone_number: '電話番号',
    confirm_password: 'パスワード確認',
    search_region_code: '地域コード検索',
};
export default Object.freeze(input);
