@use 'src/scss/underscore' as _;

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .image {
    > svg {
      width: 256px;
      height: 256px;
    }
  }

  .title {
    font: var(--font-title-1);
  }

  .description {
    max-width: 470px;
    font: var(--font-body-2);
    color: var(--color-text-secondary);
    margin-top: _.unit(2);
  }

  .button {
    margin-top: _.unit(6);
  }
}

