@use 'src/scss/underscore' as _;

.container {
  display: flex;
  align-items: center;
  font: var(--font-label-2);
  gap: _.unit(2);
  padding-right: _.unit(5);

  > svg {
    flex-shrink: 0;
  }
}
