const get_started = {
    page_title: 'Comenzar',
    progress: 'Guía de inicio: {{completado}}/{{total}}',
    progress_dropdown_title: 'Algunas cosas que puedes hacer...',
    title: 'Algo para explorar que te ayudará a tener éxito',
    subtitle_part1: 'Algunas cosas que puedes hacer para obtener rápidamente el valor de Logto',
    subtitle_part2: 'Soy un profesional y he completado todos los pasos.',
    hide_this: 'Ocultar esto',
    confirm_message: '¿Estás seguro de que quieres ocultar esta página? Esta acción no se puede deshacer.',
    check_preview_title: 'Comprueba la vista previa en vivo',
    check_preview_subtitle: 'Prueba la experiencia de inicio de sesión de Logto ahora para ver cómo funciona',
    integration_title: 'Crea e integra tu aplicación',
    integration_subtitle: 'Configura la autenticación de Logto para tu aplicación nativa, de una sola página, de máquina a máquina o tradicional',
    custom_sie_title: 'Personaliza la experiencia de inicio de sesión',
    custom_sie_subtitle: 'Desbloquea una amplia gama de escenarios con opciones avanzadas',
    passwordless_title: 'Escalabilidad del inicio de sesión sin contraseña agregando tus propios conectores',
    passwordless_subtitle: 'Prueba el inicio de sesión sin contraseña y habilita una experiencia segura y sin fricciones para tus clientes',
    community_title: 'Únete a nuestra comunidad de Discordia',
    community_subtitle: 'Únete a nuestro canal público para chatear con otros desarrolladores',
    management_api_title: 'Interactúa con la API de administración',
    management_api_subtitle: 'Conecta directamente tu sistema de autenticación a nuestra API de administración',
    further_readings_title: 'Lecturas adicionales',
    further_readings_subtitle: 'Consulta nuestra documentación paso a paso y basada en escenarios sin conceptos tediosos',
    add_rbac_title: 'Agrega control de acceso basado en roles para proteger tus recursos',
    add_rbac_subtitle: 'Controla tus recursos a través de una autorización basada en roles escalable para diversos casos de uso.',
};
export default Object.freeze(get_started);
