@use 'src/scss/underscore' as _;

.container {
  display: flex;
  align-items: center;
  gap: _.unit(6);
  padding: _.unit(6);
  background-color: var(--color-info-container);
  margin: 0 _.unit(-6) _.unit(-6);

  .description {
    flex: 1;
    flex-shrink: 0;
    font: var(--font-body-2);
  }
}
