const action = {
    sign_in: 'Inloggen',
    continue: 'Doorgaan',
    create_account: 'Account aanmaken',
    create_account_without_linking: 'Account aanmaken zonder te koppelen',
    create: 'Aanmaken',
    enter_passcode: 'Voer verificatiecode in',
    confirm: 'Bevestigen',
    cancel: 'Annuleren',
    save_password: 'Wachtwoord opslaan',
    bind: 'Koppelen met {{address}}',
    bind_and_continue: 'Koppelen en doorgaan',
    back: 'Ga terug',
    nav_back: 'Terug',
    agree: 'Akkoord',
    got_it: 'Begrepen',
    sign_in_with: 'Doorgaan met {{name}}',
    forgot_password: 'Wachtwoord vergeten?',
    switch_to: 'Schakel over naar {{method}}',
    sign_in_via_passcode: 'Inloggen met verificatiecode',
    sign_in_via_password: 'Inloggen met wachtwoord',
    change: 'Wijzig {{method}}',
    link_another_email: 'Koppel een ander e-mailadres',
    link_another_phone: 'Koppel een ander telefoonnummer',
    link_another_email_or_phone: 'Koppel een ander e-mailadres of telefoonnummer',
    show_password: 'Wachtwoord tonen',
};
export default Object.freeze(action);
