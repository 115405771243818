@use 'src/scss/underscore' as _;
@use './screen-size.module.scss' as _screenSize;

.preview {
  background: var(--color-surface-variant);

  iframe {
    border: none;
  }

  &.web {
    position: relative;

    .deviceWrapper {
      overflow: hidden;
      width: _screenSize.$web-width;
      height: _screenSize.$web-height;
      margin: 0 auto;

      .device {
        iframe {
          width: _screenSize.$web-iframe-width;
          height: _screenSize.$web-iframe-height;
          transform: scaleX(_screenSize.$web-scale-x) scaleY(_screenSize.$web-scale-y);
          margin-left: _screenSize.$web-to-wrapper-offset-x;
          margin-top: _screenSize.$web-to-wrapper-offset-y;
        }
      }
    }
  }

  &.mobile {
    position: relative;

    .deviceWrapper {
      overflow: hidden;
      padding: _.unit(10) 0;


      .device {
        border-radius: 26px;
        overflow: hidden;
        margin: 0 auto;
        width: _screenSize.$mobile-width;
        height: calc(_screenSize.$mobile-height + 44px); // 44px is the height of the top bar

        .topBar {
          display: flex;
          align-items: center;
          padding: _.unit(3) _.unit(4);

          .time {
            flex: 1;
            font: var(--font-label-2);
            transform-origin: left center;
            transform: scale(0.8);
          }
        }

        &.dark {
          // Sync with iframe's UI color
          background: #1a1c1d;

          .topBar {
            color: #fff;
          }
        }

        &.light {
          // Sync with iframe's UI color
          background: #fff;

          .topBar {
            color: #000;
          }
        }

        iframe {
          width: _screenSize.$mobile-iframe-width;
          height: _screenSize.$mobile-iframe-height;
          transform: scaleX(_screenSize.$mobile-scale-x) scaleY(_screenSize.$mobile-scale-y);
          margin-left: _screenSize.$mobile-to-wrapper-offset-x;
          margin-top: _screenSize.$mobile-to-wrapper-offset-y;
        }
      }
    }
  }
}
