@use 'src/scss/underscore' as _;

.title {
  @include _.shimmering-animation;
  height: 16px;
  width: 80px;
}

.text {
  @include _.shimmering-animation;
  width: 100%;
  height: 10px;
}

.text + .text {
  margin-top: _.unit(2);
}

.field {
  @include _.shimmering-animation;
  width: 100%;
  height: 44px;
}

.field + .field {
  margin-top: _.unit(6);
}
