@use 'src/scss/underscore' as _;

.deletionButtonContainer {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-divider);
  border-radius: _.unit(2);
  padding: _.unit(4);

  .description {
    flex: 1;
    margin-right: _.unit(2);
    font: var(--font-body-2);
  }
}
