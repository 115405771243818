@use 'src/scss/underscore' as _;

.languageSelector {
  .input {
    position: relative;

    .addLanguageButton {
      width: 100%;
      height: 38px;
      background: unset;
    }

    .buttonIcon {
      color: var(--color-text-secondary);
    }
  }

  .dropDown {
    position: absolute;
    width: 168px;
    margin: _.unit(1) 0;
    padding: _.unit(1);
    background: var(--color-float);
    border: 1px solid var(--color-divider);
    border-radius: 8px;
    max-height: 288px;
    overflow-y: auto;
    box-shadow: var(--shadow-2);

    .dropDownItem {
      width: 100%;
      border-radius: _.unit(2);
      padding: _.unit(2);
      cursor: pointer;
      font: var(--font-body-2);

      &:hover {
        background: var(--color-hover);
      }

      .languageName {
        color: var(--color-text);
      }

      .languageTag {
        color: var(--color-text-secondary);
      }
    }
  }
}

