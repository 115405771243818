@use 'src/scss/underscore' as _;

.dropdown {
  min-width: unset;
}

.addAnotherDropdown {
  min-width: 208px;
}

.plusIcon {
  color: var(--color-text-secondary);
}

.title {
  display: flex;
  align-items: center;

  .name {
    font: var(--font-body-2);
    margin-left: _.unit(3);
  }

  .icon {
    width: 16px;
    height: 16px;
    object-fit: cover;
    margin-left: _.unit(1);
    color: var(--color-text-secondary);
  }
}
