const input = {
    username: 'Nome utente',
    password: 'Password',
    email: 'Email',
    or_join: 'o',
    phone_number: 'Numero di telefono',
    confirm_password: 'Conferma password',
    search_region_code: 'Codice regione di ricerca',
};
export default Object.freeze(input);
