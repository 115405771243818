@use 'src/scss/underscore' as _;

.app {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
}

.topbarShadow {
  box-shadow: var(--shadow-2);
}
