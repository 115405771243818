@use 'src/scss/underscore' as _;

.container {
  display: flex;
  gap: _.unit(7);
}

.filters {
  display: flex;
  flex-direction: column;
  gap: _.unit(4);
  padding: _.unit(8) 0 _.unit(8) _.unit(11);
  flex-shrink: 0;
  overflow-y: auto;

  label {
    font: var(--font-label-2);
    color: var(--color-text);
  }

  .searchInput {
    svg {
      color: var(--color-text-secondary);
    }
  }

  .checkboxGroupContainer {
    position: relative;
  }

  .checkboxGroup {
    gap: _.unit(4);
  }

  .proTag {
    position: absolute;
    right: 0;
    bottom: 2px;
    z-index: 1;
  }
}

.groups {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: _.unit(8);
  overflow-y: auto;

  > div {
    flex: unset;
  }
}

.guideGroup {
  flex: 1;
  margin: _.unit(8) _.unit(8) 0 0;
}

.emptyPlaceholder {
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 70%;
}
