@use 'src/scss/underscore' as _;

.platforms {
  margin-top: _.unit(6);

  .title {
    font: var(--font-label-2);
    margin-bottom: _.unit(3);
  }
}
