const get_started = {
    page_title: 'Começar',
    progress: 'Rrimeiros passos: {{completed}}/{{total}}',
    progress_dropdown_title: 'Algumas coisas que você pode fazer...',
    title: 'Algo para explorar para ajudá-lo a ter sucesso',
    subtitle_part1: 'Algumas coisas que você pode fazer para utilizar rapidamente o Logto',
    subtitle_part2: 'Já finalizei os passos. ',
    hide_this: 'Esconder isso',
    confirm_message: 'Tem certeza de que deseja ocultar esta página? Essa ação não pode ser desfeita.',
    check_preview_title: 'Verificar o preview ao vivo',
    check_preview_subtitle: 'Experimente a experiência do Logto agora para ver como funciona',
    integration_title: 'Criar e integrar a sua aplicação',
    integration_subtitle: 'Configure a autenticação do Logto para sua aplicação nativa, de página única, máquina a máquina ou tradicional',
    custom_sie_title: 'Personalizar a experiência de login',
    custom_sie_subtitle: 'Desbloqueie uma ampla gama de cenários com configurações avançadas',
    passwordless_title: 'Aumente a escala do login sem senha adicionando seus próprios conectores',
    passwordless_subtitle: 'Experimente o login sem senha e habilite uma experiência segura e sem atritos para o seu cliente',
    community_title: 'Junte-se à nossa comunidade no Discord',
    community_subtitle: 'Junte-se ao nosso canal público para conversar com outros desenvolvedores',
    management_api_title: 'Interagir com Management API',
    management_api_subtitle: 'Conecte diretamente seu sistema de autenticação à nossa Management API',
    further_readings_title: 'Leituras adicionais',
    further_readings_subtitle: 'Confira nossos documentos passo a passo baseados em cenários sem conceitos tediosos',
    add_rbac_title: 'Adicionar controle de acesso baseado em função para proteger seus recursos',
    add_rbac_subtitle: 'Controle seu recurso por meio de autorização de função escalável para diversos casos de uso.',
};
export default Object.freeze(get_started);
