@use 'src/scss/underscore' as _;

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: min-content;
}

.tableContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;

  .filterContainer {
    background-color: var(--color-layer-1);
    border-radius: 12px 12px 0 0;
    padding: _.unit(3) _.unit(3) 0;

    .filter {
      border-bottom: 1px solid var(--color-divider);
      padding-bottom: _.unit(3);
    }
  }

  table {
    border: none;
    border-spacing: 0;
    table-layout: fixed;
    width: 100%;
  }

  .headerTable {
    background-color: var(--color-layer-1);
    border-radius: 12px 12px 0 0;
    padding: 0 _.unit(3);

    thead {
      tr {
        th {
          font: var(--font-label-2);
          color: var(--color-text);
          border-bottom: unset;
          padding: _.unit(3);
          text-align: left;
        }
      }
    }

    &.hideTopBorderRadius {
      border-radius: 0;
    }
  }

  .bodyTable {
    overflow-y: auto;
    padding: 0 _.unit(3) _.unit(3);
    background-color: var(--color-layer-1);
    border-radius: 0 0 12px 12px;

    tbody {
      tr {
        cursor: default;

        td {
          font: var(--font-body-2);
          border-top: 1px solid var(--color-divider);
          border-bottom: unset;
          padding: _.unit(3);
        }

        &.clickable {
          cursor: pointer;
        }
      }
    }

    &.empty {
      height: 100%;

      table {
        height: 100%;

        tbody {
          height: 100%;
        }
      }
    }
  }

  tr.hoverEffect:hover {
    background: var(--color-hover);

    td {
      border-top: 1px solid transparent;
    }

    + tr {
      td {
        border-top: 1px solid transparent;
      }
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  &.hasBorder {
    .filterContainer {
      border: 1px solid var(--color-divider);
      border-bottom: unset;

      .filter {
        border-bottom: unset;
      }
    }

    .headerTable {
      padding: 0;
      border: 1px solid var(--color-divider);
    }

    .bodyTable {
      padding: 0;
      border: 1px solid var(--color-divider);
      border-top: unset;

      tr:first-child td {
        border-top: 1px solid transparent;
      }
    }

    tr.hoverEffect:hover {
      td:first-child,
      td:last-child {
        border-radius: 0;
      }
    }
  }
}

.pagination {
  margin-top: _.unit(4);
}
