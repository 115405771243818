const description = {
    email: 'e-mail',
    phone_number: 'telefoonnummer',
    username: 'gebruikersnaam',
    reminder: 'Herinnering',
    not_found: '404 Niet gevonden',
    agree_with_terms: 'Ik heb gelezen en ga akkoord met de ',
    agree_with_terms_modal: 'Om verder te gaan, ga akkoord met de <link></link>.',
    terms_of_use: 'Gebruiksvoorwaarden',
    sign_in: 'Inloggen',
    privacy_policy: 'Privacybeleid',
    create_account: 'Account aanmaken',
    or: 'of',
    and: 'en',
    enter_passcode: 'De verificatiecode is verzonden naar uw {{address}} {{target}}',
    passcode_sent: 'De verificatiecode is opnieuw verzonden',
    resend_after_seconds: 'Opnieuw verzenden na <span>{{seconds}}</span> seconden',
    resend_passcode: 'Verificatiecode opnieuw verzenden',
    create_account_id_exists: 'Het account met {{type}} {{value}} bestaat al, wilt u inloggen?',
    link_account_id_exists: 'Het account met {{type}} {{value}} bestaat al. Wilt u koppelen?',
    sign_in_id_does_not_exist: 'Het account met {{type}} {{value}} bestaat niet, wilt u een nieuw account aanmaken?',
    sign_in_id_does_not_exist_alert: 'Het account met {{type}} {{value}} bestaat niet.',
    create_account_id_exists_alert: 'Het account met {{type}} {{value}} is gekoppeld aan een ander account. Probeer een andere {{type}}.',
    social_identity_exist: 'De {{type}} {{value}} is gekoppeld aan een ander account. Probeer een andere {{type}}.',
    bind_account_title: 'Koppel of maak een account',
    social_create_account: 'U kunt een nieuw account aanmaken.',
    social_link_email: 'U kunt een ander e-mailadres koppelen',
    social_link_phone: 'U kunt een ander telefoonnummer koppelen',
    social_link_email_or_phone: 'U kunt een ander e-mailadres of telefoonnummer koppelen',
    social_bind_with_existing: 'We vinden een gerelateerd account, u kunt het direct koppelen.',
    reset_password: 'Wachtwoord opnieuw instellen',
    reset_password_description: 'Voer de {{types, list(type: disjunction;)}} in die aan uw account zijn gekoppeld en we sturen u de verificatiecode om uw wachtwoord opnieuw in te stellen.',
    new_password: 'Nieuw wachtwoord',
    set_password: 'Wachtwoord instellen',
    password_changed: 'Wachtwoord gewijzigd',
    no_account: 'Nog geen account? ',
    have_account: 'Heeft u al een account?',
    enter_password: 'Voer wachtwoord in',
    enter_password_for: 'Log in met het wachtwoord voor {{method}} {{value}}',
    enter_username: 'Stel gebruikersnaam in',
    enter_username_description: 'Gebruikersnaam is een alternatief voor inloggen. Gebruikersnaam mag alleen letters, cijfers en underscores bevatten.',
    link_email: 'Koppel e-mail',
    link_phone: 'Koppel telefoon',
    link_email_or_phone: 'Koppel e-mail of telefoon',
    link_email_description: 'Voor extra beveiliging, koppel uw e-mail aan het account.',
    link_phone_description: 'Voor extra beveiliging, koppel uw telefoon aan het account.',
    link_email_or_phone_description: 'Voor extra beveiliging, koppel uw e-mail of telefoon aan het account.',
    continue_with_more_information: 'Voor extra beveiliging, vul de onderstaande accountdetails aan.',
    create_your_account: 'Maak uw account aan',
    sign_in_to_your_account: 'Log in op uw account',
    no_region_code_found: 'Geen regiocode gevonden',
    verify_email: 'Verifieer uw e-mail',
    verify_phone: 'Verifieer uw telefoonnummer',
};
export default Object.freeze(description);
