@use 'src/scss/underscore' as _;

.radioGroup {
  input[type='radio'] {
    appearance: none;
    position: absolute;
    margin: 0;
    width: 0;
    height: 0;
  }
}

.compact,
.small {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}
