@use 'src/scss/underscore' as _;

.languageNav {
  width: 185px;
  padding: _.unit(3) _.unit(2);
  flex-shrink: 0;
  background-color: var(--color-layer-light);
  border-right: 1px solid var(--color-divider);
  display: flex;
  flex-direction: column;

  .languageItemList {
    flex: 1;
    margin-top: _.unit(3);
    overflow-y: auto;
  }
}
