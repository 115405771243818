const input = {
    username: '用户名',
    password: '密码',
    email: '邮箱',
    or_join: '或者',
    phone_number: '手机号',
    confirm_password: '确认密码',
    search_region_code: '搜索区域码',
};
export default Object.freeze(input);
