@use 'src/scss/underscore' as _;

.title {
  @include _.section-head-1;
  color: var(--color-neutral-variant-60);
  display: flex;
  align-items: center;
}

.description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}
