@use 'src/scss/underscore' as _;

.languageItem {
  padding: _.unit(1.5) _.unit(3);
  margin-bottom: _.unit(1);
  cursor: pointer;
  border-radius: 8px;

  .languageName {
    color: var(--color-text);
    font: var(--font-label-2);
  }

  .languageTag {
    color: var(--color-text-secondary);
    font: var(--font-body-2);
  }

  &:hover {
    background-color: var(--color-hover-variant);
  }

  &.selected {
    background-color: var(--color-focused-variant);

    .languageName,
    .languageTag {
      color: var(--color-text-link);
    }
  }
}
