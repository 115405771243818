@use 'src/scss/underscore' as _;

.item {
  padding: _.unit(2);
  border-radius: _.unit(2);
  font: var(--font-body-2);
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;

  &:hover {
    background: var(--color-hover);
  }

  &.danger {
    color: var(--color-error);
  }

  .icon {
    display: flex;
    align-items: center;
    margin-right: _.unit(4);
  }
}
