const input = {
    username: '用戶名',
    password: '密碼',
    email: '電郵',
    or_join: '或',
    phone_number: '手機號碼',
    confirm_password: '確認密碼',
    search_region_code: '搜索區域碼',
};
export default Object.freeze(input);
