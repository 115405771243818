@use 'src/scss/underscore' as _;

.container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cardTitle {
  flex-shrink: 0;
}

.tabs {
  margin: _.unit(4) 0;
}
