const input = {
    username: 'Gebruikersnaam',
    password: 'Wachtwoord',
    email: 'E-mail',
    or_join: 'of',
    phone_number: 'Telefoonnummer',
    confirm_password: 'Bevestig wachtwoord',
    search_region_code: 'Zoek regiocode',
};
export default Object.freeze(input);
