const input = {
    username: '사용자 이름',
    password: '비밀번호',
    email: '이메일',
    or_join: '또는',
    phone_number: '휴대전화번호',
    confirm_password: '비밀번호 확인',
    search_region_code: '지역 코드 검색',
};
export default Object.freeze(input);
