@use 'src/scss/underscore' as _;

.containsTableLayout {
  height: 100%;
}

.header {
  padding: _.unit(6);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .webhookIcon {
    margin-left: _.unit(2);
    width: 60px;
    height: 60px;
  }

  > *:not(:first-child) {
    margin-left: _.unit(6);
  }

  .metadata {
    flex: 1;

    > div {
      display: flex;
      align-items: center;
    }

    .title {
      font: var(--font-title-1);
      color: var(--color-text);
    }

    .text {
      font: var(--font-label-2);
      color: var(--color-text-secondary);
      margin-right: _.unit(2);
    }

    .verticalBar {
      @include _.vertical-bar;
      height: 12px;
      margin: 0 _.unit(2);
    }

    .state {
      display: flex;
      align-items: center;
      font: var(--font-body-2);

      .successRate {
        margin-right: _.unit(1);
      }
    }
  }
}

.icon {
  color: var(--color-text-secondary);
}
