const input = {
    username: 'Utilizador',
    password: 'Palavra-passe',
    email: 'Email',
    or_join: 'ou',
    phone_number: 'Telefone',
    confirm_password: 'Confirmar palavra-passe',
    search_region_code: 'Procurar código de região',
};
export default Object.freeze(input);
