const input = {
    username: 'Kullanıcı Adı',
    password: 'Şifre',
    email: 'E-posta Adresi',
    or_join: 'veya',
    phone_number: 'Telefon Numarası',
    confirm_password: 'Şifreyi Doğrula',
    search_region_code: 'Bölge kodunu ara',
};
export default Object.freeze(input);
