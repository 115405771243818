@use 'src/scss/underscore' as _;

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  >:not(:first-child) {
    margin-top: _.unit(4);
  }

  .header {
    display: flex;
    align-items: center;
    padding: _.unit(6);
    border-radius: 16px;
    background-color: var(--color-layer-1);

    .icon {
      @include _.shimmering-animation;
      width: 60px;
      height: 60px;
      border-radius: 12px;
      margin-right: _.unit(6);
    }

    .wrapper {
      display: flex;
      flex-direction: column;

      .title {
        @include _.shimmering-animation;
        width: 113px;
        height: 28px;
      }

      .tags {
        @include _.shimmering-animation;
        width: 453px;
        height: 20px;
        margin-top: _.unit(3);
      }
    }

    .button {
      @include _.shimmering-animation;
      width: 158px;
      height: 44px;
    }
  }

  .tabBar {
    width: 100%;
    height: 28px;
    @include _.shimmering-animation;
  }
}
