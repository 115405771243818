@use 'src/scss/underscore' as _;

.container {
  .tabBar {
    width: 100%;
    height: 28px;
    @include _.shimmering-animation;
  }

  .content {
    .card {
      background-color: var(--color-layer-1);
      border-radius: 16px;
      padding: _.unit(6);

      >:not(:first-child) {
        margin-top: _.unit(6);
      }

      .title {
        @include _.shimmering-animation;
        width: 80px;
        height: 16px;
      }

      .field {
        @include _.shimmering-animation;
        width: 100%;
        height: 44px;
      }

      &:not(:first-child) {
        margin-top: _.unit(3);
      }
    }

    .preview {
      flex: 1;

      .header {
        display: flex;
        width: 100%;
        height: 103px;
        padding: _.unit(6);
        background-color: var(--color-layer-1);
        border-radius: 16px 16px 0 0;

        .info {
          .title {
            width: 113px;
            height: 20px;
            @include _.shimmering-animation;
          }

          .subtitle {
            width: 177px;
            height: 16px;
            margin-top: _.unit(4);
            @include _.shimmering-animation;
          }
        }

        .button {
          width: 104px;
          height: 30px;
          margin-left: _.unit(2.5);
          @include _.shimmering-animation;
        }
      }

      .previewContent {
        background: var(--color-surface-variant);
        padding: _.unit(6);
        border-radius: 0 0 16px 16px;
        height: 450px;
        overflow: hidden;

        .mobile {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 375px;
          height: 667px;
          background: var(--color-surface);
          margin: 0 auto;
          padding: _.unit(6);
          border-radius: 16px;
          transform: scale(0.6);
          transform-origin: top;

          .logo {
            width: 64px;
            height: 64px;
            margin-top: _.unit(16);
            @include _.shimmering-animation;
          }

          .slogan {
            width: 177px;
            height: 16px;
            margin: _.unit(3) 0 _.unit(10);
            @include _.shimmering-animation;
          }

          .field {
            width: 100%;
            height: 36px;
            margin-top: _.unit(3);
            @include _.shimmering-animation;
          }

          .button {
            width: 100%;
            height: 36px;
            margin-top: _.unit(10);
            @include _.shimmering-animation;
          }

          .social {
            width: 180px;
            height: 24px;
            margin-top: _.unit(3);
            @include _.shimmering-animation;
          }
        }
      }
    }
  }
}
