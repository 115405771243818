const get_started = {
    page_title: 'Commencer',
    progress: 'Guide de démarrage : {{completed}}/{{total}}',
    progress_dropdown_title: 'Il y a quelques choses que vous pouvez faire...',
    title: 'Quelque chose à explorer pour vous aider à réussir',
    subtitle_part1: 'Voici quelques mesures que vous pouvez prendre pour tirer rapidement profit de Logto',
    subtitle_part2: "J'en ai fini avec cette installation. ",
    hide_this: 'Cacher cela',
    confirm_message: 'Êtes-vous sûr de vouloir masquer cette page ? Cette action ne peut être annulée.',
    check_preview_title: "Vérifiez l'aperçu en direct",
    check_preview_subtitle: "Essayez maintenant l'expérience de connexion Logto pour voir comment elle fonctionne.",
    integration_title: 'Créez et intégrez votre application',
    integration_subtitle: "Configurez l'authentification Logto pour votre application native, à page unique, machine à machine ou traditionnelle",
    custom_sie_title: "Personnalisez l'expérience de connexion",
    custom_sie_subtitle: 'Déverrouillez une grande variété de scénarios avec des paramètres avancés',
    passwordless_title: 'Évolutivité de la connexion sans mot de passe en ajoutant vos propres connecteurs',
    passwordless_subtitle: 'Essayez la connexion sans mot de passe et offrez à vos clients une expérience sécurisée et sans friction',
    community_title: 'Rejoignez notre communauté discord',
    community_subtitle: "Rejoignez notre chaîne publique pour discuter avec d'autres développeurs",
    management_api_title: 'Interagissez avec Management API',
    management_api_subtitle: "Connectez directement votre système d'authentification à notre Management API",
    further_readings_title: 'Lectures complémentaires',
    further_readings_subtitle: 'Consultez nos documents étape par étape et basés sur des scénarios, sans concepts fastidieux',
    add_rbac_title: "Ajoutez un contrôle d'accès basé sur les rôles pour protéger vos ressources",
    add_rbac_subtitle: "Contrôlez vos ressources par l autorisation de rôle évolutive pour divers cas d'utilisation.",
};
export default Object.freeze(get_started);
