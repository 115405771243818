@use 'src/scss/underscore' as _;

.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-width: min-content;

  > *:not(:first-child) {
    margin-top: _.unit(4);
  }
}

.backLink {
  margin: _.unit(1) 0 0 _.unit(1);
  user-select: none;
}
