@use 'src/scss/underscore' as _;

.container {
  border-radius: 8px;
  background-color: var(--color-layer-1);
  display: flex;
  align-items: center;
  padding: _.unit(1);
  gap: 12px;
  border: 1px solid var(--color-surface-5);
}

